import {Component, Inject, OnInit, ViewChild, ViewEncapsulation,} from '@angular/core';
import {AppDatastore} from '../../core/services/app-store/app.datastore';
import {ActivatedRoute} from "@angular/router";
import {UntypedFormBuilder, Validators} from "@angular/forms";
import { HttpClient } from "@angular/common/http";
import {NxModalRef} from "@aposin/ng-aquila/modal";
import {TrackingService} from "../../core/services/trackingservice/tracking.service";
import countries from 'i18n-iso-countries';

import de from 'i18n-iso-countries/langs/de.json';
import fr from 'i18n-iso-countries/langs/fr.json';
import it from 'i18n-iso-countries/langs/it.json';
import en from 'i18n-iso-countries/langs/en.json';
import {NxMultiStepperComponent} from "@aposin/ng-aquila/progress-stepper";
import {StepperSelectionEvent} from "@angular/cdk/stepper";
import {TemplateRendererService} from '../../core/services/template-renderer-service/template-renderer.service';
import {NewLeadComponent} from '../../mail-templates/new-lead/new-lead.component';
import {SourcesService} from '../../core/services/sources/sources.service';
import mailShell from '../../core/services/template-renderer-service/mail-shell';
import {APP_BASE_HREF} from "@angular/common";
import {TranslateService} from "@ngx-translate/core";
import {Agency, AgencyListService} from "../../core/services/agency-list.service";


countries.registerLocale(de);
countries.registerLocale(fr);
countries.registerLocale(it);
countries.registerLocale(en);

// @ts-ignore

/**
 * Validates phone numbers. International numbers are accepted if they contain a country code.
 *
 * @param type? (optional) Type of number, can be `mobile` or `landline`
 */

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None,

})
export class HomeComponent implements OnInit {


  showDebug = false;
  private campaign = 'default';
  @ViewChild('stepper')
  private myStepper!: NxMultiStepperComponent;

  private jsonResponse: any;
  decodedEmail = '';
  isLeadGeneratorLink = false;
  translatedCountries: any;
  public agencies: any;


  /**
   * Init Home Page
   *
   * @param fb
   * @param trackingService
   * @param activatedRoute
   * @param appDataStore - App storage Service
   * @param httpClient
   * @param templateRenderer
   * @param sourcesService
   * @param translateService
   * @param agencyListService
   * @param baseUrl
   */
  constructor(private readonly fb: UntypedFormBuilder,
              private trackingService: TrackingService,
              private activatedRoute: ActivatedRoute,
              public appDataStore: AppDatastore,
              private httpClient: HttpClient,
              private templateRenderer: TemplateRendererService,
              private sourcesService: SourcesService,
              private translateService: TranslateService,
              private agencyListService: AgencyListService,
              @Inject(APP_BASE_HREF) private baseUrl: string) {
  }

  componentDialogRef!: NxModalRef<HomeComponent>;
  readonly stepThreeForm = this.fb.group({
    zip: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(4), Validators.pattern("\\d*")]],
    email: ['', Validators.required],
    phone: ['+41', [Validators.required, Validators.minLength(12)]],
    availability: '',
    firstname: ['', Validators.required]
  });


  ngOnInit() {
    this.getAgencyList();
    this.isLeadGeneratorLink = false;
    let langX = '';
    this.activatedRoute.queryParams.subscribe(params => {

      if (params['debug']) {
        this.showDebug = params['debug'];
      }
      if (params['lang']) {
        this.translatedCountries = countries.getNames(params['lang'], {select: 'official'})
        this.trackingService.appLanguage = params['lang'];
      } else {
        this.translatedCountries = countries.getNames('de', {select: 'official'})
      }
      if (params['data_zp']) {
        this.decodedEmail = atob(params['data_zp']);
        this.isLeadGeneratorLink = true;
        this.stepThreeForm.controls['zip'].clearValidators();
        this.stepThreeForm.controls['zip'].updateValueAndValidity();
      }
      if (params['d']) {
        this.decodedEmail = atob(params['d']);
        this.isLeadGeneratorLink = true;
        this.stepThreeForm.controls['zip'].clearValidators();
        this.stepThreeForm.controls['zip'].updateValueAndValidity();
        // we set the lead 'origin' (evar211) to indicate a lead from the leadgenerator.allianz.ch tool
        //TODO: how do we handle other variants like WSA etc.
        this.trackingService.ls_origin = 'LeadByYou';
      }
      if (params['cp']) {
        this.campaign = params['cp']
      }

      if (params['ga']) {
        this.trackingService.ls_gaID = params['ga'];
        // Agency have been preselected
        // Preload the translations files for this agency
        // We use getList() -> Observable<Agency[]> function so that we are sure that data from agency.json
        // are loaded when we try to get Agency and it's language
        this.agencyListService.getList().subscribe((_) => {
          const agencies = this.agencyListService.getById(params['ga']);
          for (const agency of agencies) {
            this.addAgencyLanguage(agency);
          }
        })
      }

      // jfc......
      //TODO: Fix this s*it ASAP!!! (-> L4Y TrackingService)
      langX = params['lang']
      if (typeof langX == 'undefined') {
        langX = 'de';
      }
    });
    this.trackingService.trackStep('start', langX);
  }

  public onsubmit(_event: Event): void {

    const formData = new FormData();
    let agency: Agency;

    // leadForm has been called via a leadgenerator leadlink with hashed email and GA/Campaign-ID
    if (this.decodedEmail !== '') {
      this.isLeadGeneratorLink = true;
      // determine agency attributes for tracking by GA-ID
      agency = this.agencyListService.getById(this.trackingService.ls_gaID)[0];
    } else {
      // determine agency tracking attributes by zip from input-field
      agency = this.agencyListService.getByZip(this.stepThreeForm.value.zip)[0];
    }

    // determine routing email
    if (this.isLeadGeneratorLink) {
      // base64 hashed email from lead-generator lead-link
      formData.append('email', this.decodedEmail);
      this.stepThreeForm.controls['zip'].clearValidators();
      this.stepThreeForm.controls['zip'].updateValueAndValidity();
    } else {
      if (this.stepThreeForm.value.zip !== '9999' && this.stepThreeForm.value.zip !== '9998' && this.stepThreeForm.value.zip !== '9997' && this.stepThreeForm.value.zip !== '9996' ) {
        // email determined by zip code (GA-Mail)
        formData.append('email', agency.email);
      } else {
        // test mail with zip 9999
        formData.append('email', this.stepThreeForm.value.email);
      }
    }
    // always bcc our internal mailbox for each lead generated
    formData.append('bcc', 'leadswettbewerb@allianz.ch');


    // update analytics datalayer
    this.trackingService.ls_gaID = agency.id;

    this.isLeadGeneratorLink ?
      this.appDataStore.userPlz = agency.plz[0] :
      this.appDataStore.userPlz = this.stepThreeForm.value.zip;

    this.trackingService.trackAgent();



    // We save the application language and change the currentLanguage to the agency language
    // This is to retrieve the agency local subject for the email we are gonna send
    // The agency language translations are hopefully loaded by now here
    // The translations might not be loaded if agency language is different then application language
    // and user is on slower network or user clcks Submit button right after entering their zipCode
    // then we restore the application language.
    // Doing this by using `set currentLang(lang)` doesn't cause application to rerender
    // with updated language so it's perfect for getting instant value of translation
    // Better way would be to ise Observables on template Rendere (not implemented) and on translation service
    // before we send the email.
    const currentLanguage = this.translateService.currentLang
    this.translateService.currentLang = agency.language.toLowerCase()
    let subject = this.translateService.instant('mail.subject');
    let source = this.sourcesService.getSource('allianz-leadform', this.campaign);
    this.translateService.currentLang = currentLanguage



    // data for the lead-mail (customer input and source mapping)
    if (!source) source = {was: "", woher: "", url: ""}
    let templateData = {
      name: this.stepThreeForm.value.firstname,
      zipCode: this.stepThreeForm.value.zip,
      emailAddress: this.stepThreeForm.value.email,
      phoneNumber: this.stepThreeForm.value.phone,
      availability: this.stepThreeForm.value.availability,
      source: source,
      quelle: this.trackingService.ls_src,
      lang: agency.language,
    }

    let emailContent = this.templateRenderer.render(NewLeadComponent, templateData)
    emailContent = mailShell.replace('{{mailcontent}}', emailContent)

    formData.append('content', emailContent);
    formData.append('subject', subject);

    // legacy php script with header and footer components
    // const url = "https://wfz-2023.lespals.com/sendmail_2024.php";
    const url = "https://allianz-mailer.lespals.com/sendmail_prod.php";
    const xhr = new XMLHttpRequest();
    xhr.open('POST', url, true);
    xhr.onload = function () {
    };
    xhr.send(formData);
    this.myStepper.next();
  }

  updateStepper($event: StepperSelectionEvent) {

    // forgive me father for my sins...
    //TODO: Refactor this POS tracking implementation asap
    let lang = '';
    this.activatedRoute.queryParams.subscribe(params => {
      lang = params['lang'];
    })
    if (typeof lang == 'undefined') {
      lang = 'de';
    }


    switch ($event.selectedIndex) {

      case 0: {
        this.trackingService.trackStep('start', lang);
        break;
      }
      case 1: {
        this.trackingService.trackStep('success', lang);
        // @ts-ignore
        _satellite.track("DCR: Consultation Complete");
        break;
      }
      default: {
        break;
      }
    }
  }

  onZipChange() {
    const agenciesWithZip = this.agencyListService.getByZip(this.stepThreeForm.value.zip)
    const availableLanguages = [...Object.keys(this.translateService.translations)];
    for (const agency of agenciesWithZip) {
      if (!availableLanguages.includes(agency.language.toLowerCase())) {
        this.translateService.reloadLang(agency.language.toLowerCase())
        availableLanguages.push(agency.language.toLowerCase())
        this.addAgencyLanguage(agency);
      }
    }
  }

  getAgencyList() {
    this.agencyListService.getList()
      .subscribe((list: any) => this.agencies = list)
  }

  /**
   * Preloads missing language translation files for the agency
   * so the translations can be used when generating lead email
   *
   * @param agency agency for which the language files will be preloaded
   */
  private addAgencyLanguage(agency: Agency) {
    const availableLanguages = [...Object.keys(this.translateService.translations)];
    if (!availableLanguages.includes(agency.language.toLowerCase())) {
      this.translateService.reloadLang(agency.language.toLowerCase())
      availableLanguages.push(agency.language.toLowerCase())
    }
  }
}
