import {NgModule} from '@angular/core';
import {
  RouterModule,
  Routes
} from '@angular/router';

// -------- App Pages ---------------------
import {HomeComponent} from './modules/demohome/home.component';
// -------- End App Pages -----------------

export const appRoutes: Routes = [
  {
    path: '',
    component: HomeComponent,
    pathMatch: 'full',
  },
  {
    path: 'home',
    component: HomeComponent
  }

];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, {useHash: false})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
