<!-- ------------------------------------------------------------------------------------------------------------------ -->


<!-- ------------------------------------------------------------------------------------------------------------------ -->

<div class="main-wrapper " nxLayout="grid maxwidth">
  <app-window-portal *ngIf="showDebug" name="mailPreview"><app-new-lead name="John Doe" zipCode="9999" emailAddress="test@example.com" phoneNumber="+49123123123" availability="8-12" isCustomer="false" [source]='{"woher": "LeadByYou: Dein persönliches standard Leadformular","was": "Hat eine Beratung angefordert", "url": "https://www.allianz.ch"}' quelle="Test"></app-new-lead></app-window-portal>
  <div class="nx-margin-top-2m" nxRow>
    <div class="nx-margin-bottom-2m" nxCol="12,12,8,8,8" colOffset="0,0,2,2,2">
      <nx-multi-stepper #stepper [linear]="true" currentStepLabel="Step" (selectionChange)="updateStepper($event)">

        <nx-step [stepControl]="stepThreeForm" label="{{'copy.step_3.label'|translate}}">
          <form [formGroup]="stepThreeForm">
            <div class="nx-margin-top-4m" nxRow>


<!--              <div nxCol="12,12" class="nx-margin-bottom-m">-->
<!--                <nx-checkbox labelSize="large" class=""-->
<!--                             formControlName="isCustomer">{{'app.form.client'|translate}}-->
<!--                </nx-checkbox>-->
<!--              </div>-->
              <div class="nx-margin-bottom-2m" nxCol="12,12,12" colOffset="0">
                <nx-formfield label="{{'core.label.firstname'|translate}}, {{'core.label.lastname'|translate}}">
                  <input formControlName="firstname" nxInput required/>
                </nx-formfield>
              </div>
              <div class="nx-margin-bottom-2m" nxCol="12,12,12,6" colOffset="0">
                <nx-formfield label="{{'core.label.email'|translate}}">
                  <input formControlName="email" nxInput type="email"/>
                </nx-formfield>
              </div>
              <div class="nx-margin-bottom-2m" nxCol="12,12,12,6" colOffset="0">
                <nx-formfield>
                  <nx-formfield-label>{{ 'core.label.phonenumber'|translate }}</nx-formfield-label>
                  <nx-phone-input formControlName="phone" countryCode="CH"></nx-phone-input>
                </nx-formfield>
              </div>
              <div class="" nxCol="12,12,12" colOffset="0" [hidden]="isLeadGeneratorLink">
                <nx-formfield label="{{'core.label.zip'|translate}}">
<!--                  <input formControlName="zip" nxInput (change)="changeZip()"/>-->
                  <input formControlName="zip" nxInput (change)="onZipChange()"/>
                  <span nxFormfieldPrefix>CH-</span>
                </nx-formfield>
              </div>
              <div nxCol="12,12" class="nx-margin-bottom-2m nx-margin-top-2m" [hidden]="isLeadGeneratorLink">
                <p nxCopytext="small" class="u-text-center">
                  {{'app.form.zipcodehint'|translate}}
                </p>
              </div>
              <div class="nx-margin-bottom-4m" nxCol="12" colOffset="0">
                <nx-checkbox-group formControlName="availability" >
                  <nx-label>{{'core.label.availability'|translate}}</nx-label>
                  <div class="horizontal-checkboxes">
                  <nx-checkbox value="8-12 Uhr" class="">{{'core.label.time_1'|translate}}</nx-checkbox>
                  <nx-checkbox value="12-13 Uhr" class="nx-margin-left-s">{{'core.label.time_2'|translate}}</nx-checkbox>
                  <nx-checkbox value="13-17 Uhr" class="nx-margin-left-s">{{'core.label.time_3'|translate}}</nx-checkbox>
                  <nx-checkbox value="17-20 Uhr" class="nx-margin-left-s">{{'core.label.time_4'|translate}}</nx-checkbox>
                  </div>
                </nx-checkbox-group>
              </div>
            </div>
          </form>
          <div class="nx-margin-top-l " nxRow>
            <div nxCol="12,12" class="nx-margin-bottom-2m">
              <p nxCopytext="medium" class=" u-text-center" [innerHtml]="'app.form.legal'|translate">

              </p>
            </div>
            <div class="nx-margin-bottom-2m u-text-center" nxCol="12" colOffset="0">

              <button nxButton="primary " (click)="onsubmit($event)" type="button" class="nx-margin-top-m" [disabled]="!stepThreeForm.valid">
                {{ 'core.button.submit' | translate }}
              </button>
            </div>
          </div>
        </nx-step>
        <nx-step label="{{'copy.step_4.label'|translate}}">
          <div class="nx-margin-top-l" nxRow>

            <div class="nx-margin-bottom-2m nx-margin-top-4m  u-text-center" nxCol="12" colOffset="0">
              <nx-icon
                name="check"
                fill="true"
                outline="true"
                size="m"
                style="background:#3DA556"
              ></nx-icon>
            </div>
            <div class="nx-margin-bottom-2m u-text-center" nxCol="12" colOffset="0">
              <h2 nxHeadline="section">{{ 'success.headline' | translate }}</h2>
            </div>
            <div class="nx-margin-bottom-5xl u-text-center" nxCol="8" colOffset="2">
              <p nxCopytext="large">
                {{ 'success.copy' | translate }}
              </p>
            </div>

          </div>
        </nx-step>

      </nx-multi-stepper>

    </div>

  </div>
</div>

<!-- ------------------------------------------------------------------------------------------------------------------ -->


