export class TrackingObject implements Object {
  application: Application
  page: Page
  product: Product;

  constructor() {
    this.application = new Application();
    this.page = new Page();
    this.product = new Product();
  }
}

export class Application implements Object {
  applicationId: string
  applicationName: string
  virtualPageName: string
  startType: string
  step: Step

  constructor() {
    this.applicationId = '';
    this.applicationName = '';
    this.virtualPageName = '';
    this.startType = '';
    this.step = new Step();
  }
}

export class Page implements Object {
  pageInfo: PageInfo

  constructor() {
    this.pageInfo = new PageInfo();
  }
}

export class Step implements Object {
  type: string

  constructor() {
    this.type = '';
  }
}

export class PageInfo implements Object {
  fullURL: string
  hostname: string
  language: string
  pageHash: string | null
  pageName: string
  URLqueryParams: string

  constructor() {
    this.fullURL = '';
    this.hostname = '';
    this.language = '';
    this.pageHash = '';
    this.pageName = '';
    this.URLqueryParams = '';
  }
}
export class Product implements Object {
  primaryCategory: string
  subCategory: string

  constructor() {
    this.primaryCategory = '';
    this.subCategory = '';
  }
}
