// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.aem.prod.ts`.
// The list of file replacements can be found in `angular.json`.
// `default` environement maps to `dev` environement
export const environment = {
  stage: 'default',
  apiServerRoot: '',
  deployUrl: '/',
  baseUrl: '/',
  baseHref:'/',
  assetsBasePath: '/assets',
  adobeUrl: '',
  production: false,
  client:'allianz'
};
