import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {DEFAULT_LANGUAGE, TranslateService} from '@ngx-translate/core';
import {ComponentLocalTranslateService} from '../../core/services/local-translate-service/component-local-translate.service';
import {SourceResult} from '../../core/services/sources/sources.service'
import { TrackingService } from '../../core/services/trackingservice/tracking.service';

@Component({
  selector: 'app-new-lead',
  templateUrl: './new-lead.component.html',
  styleUrls: ['./new-lead.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {provide: TranslateService, useClass: ComponentLocalTranslateService}
  ],
})
export class NewLeadComponent implements OnInit {

  @Input() name!: String
  @Input() zipCode!: String
  @Input() emailAddress!: String
  @Input() phoneNumber!: String
  @Input() availability!: String
  @Input() isCustomer!: String
  @Input() source!: SourceResult
  @Input() quelle!: string
  @Input() lang?: string

  // Application specific information for feedback system
  private _starRating = 4;
  public get starRating() {
    if (this._starRating < 3 && this.starRating > 5) {
      const clampedValue = this._starRating < 3 ? 3 : 5;
      console.debug("Star rating has unexpected value, valid ratings values are: 3, 4, 5. Value clamped to", clampedValue);
      return clampedValue;
    }
    return this._starRating; }
  private _appName = 'allianz-leadform';
  public get feedbackUrlNegative() { return this.feedbackUrl(FeedbackType.Negative); }
  public get feedbackUrlMedium() { return this.feedbackUrl(FeedbackType.Medium); }
  public get feedbackUrlPositive() { return this.feedbackUrl(FeedbackType.Positive); }

  constructor(private sanitizer: DomSanitizer, private translateService: TranslateService, private trackingService: TrackingService) {
  }

  ngOnInit(): void {
    if (this.lang)
      this.translateService.use(this.lang.toLowerCase())
    else {
      // default language
      this.translateService.use('de');
    }
  }

  /**
   * Trusts unsafe html and renders it without sanitization
   * WARNING: Do not use unless absolutely necessary.
   * Using it may expose application to XSS attacks.
   * It should only be used on trusted input.
   **/
  trustHtml(html: string) {
    return this.sanitizer.bypassSecurityTrustHtml(html)
  }

  /**
   * Generates feedback url where the appName and startRating are configurable in component
   * Includes also additional items about the lead when available
   * @param feedbackType Feedback type
   * @returns feedback url
   */
  private feedbackUrl(feedbackType: FeedbackType): string {
    let url = `https://www.allianz.ch/de/ldp/lead-bewertung-kb.html?leads=leadScore&lApp=${this._appName}&lAr=${this._starRating}`
    if (this.trackingService.ls_gaID !== '') url = url + '&lGa=' + this.trackingService.ls_gaID;
    if (this.trackingService.ls_campaign !== '') url = url + '&lCp=' + this.trackingService.ls_campaign;
    if (this.trackingService.ls_kunde !== '') url = url + '&lKn=' + this.trackingService.ls_kunde;
    if (this.trackingService.ls_src !== '') url = url + '&lSc=' + this.trackingService.ls_src;
    if (this.trackingService.ls_werber !== '') url = url + '&lKb=' + this.trackingService.ls_werber;
    url = url + '&lKr=' + feedbackType;

    return url;
  }
}

enum FeedbackType {
  Negative = 1,
  Medium,
  Positive,
}
