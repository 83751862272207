
  <table align="center" role="presentation" cellspacing="0" cellpadding="0" border="0" width="600" style="margin: auto;" class="email-container">

    <!-- Start - Meta Bar -->
    <tbody>
      <!-- End - Meta Bar -->

      <!-- Start - Header -->
      <tr>
        <td class="header dark-wrapper-white" style="background-color: #fff; border-bottom: 1px solid #d9d9d9; padding: 28px 16px 27px 32px;">
          <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
            <tbody><tr>
              <td style="color: #006192; font-family: Arial, sans-serif; font-size: 0px; padding: 4px 0;">
                <img style="max-width: 100%;" src="https://al-cdn.s3-eu-central-1.amazonaws.com/wfz2024/email/logo.png">
              </td>
              <td class="dark-color-white" style="color: #414141; font-size: 14px; line-height: 20px; letter-spacing: 0.2px; margin: 0px; font-family: Arial, sans-serif; text-align: right;" valign="middle" width="60%">
              </td>
            </tr>
            </tbody></table>
        </td>
      </tr>{{"\n"}}
      <!-- End - Header -->

      <tr>
        <td style="background-color: #fff;">
          <img style="display: block; height: auto; max-width: 100%; width: 100%;" src="https://al-cdn.s3-eu-central-1.amazonaws.com/wfz2024/email/az-lead-email-stage.jpg" width="600" height="230" border="0" alt="">
        </td>
      </tr>


      <tr>
        <td class="dark-wrapper" style="background-color: #fff; padding: 32px 24px 24px;">
          <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
            <tbody><tr>
              <td class="dark-wrapper" align="center" valign="top">
                <h1 class="headline small-headline" style="color: #003781; font-family: Arial, sans-serif; font-weight: 400; font-size: 38px; line-height: 40px; margin: 0px;" [innerHTML]="trustHtml('mail.label.you_have_new_lead' | translate)"></h1>
              </td>
            </tr>
            </tbody>
          </table>
        </td>
      </tr>{{"\n"}}
      <tr>
        <td class="dark-wrapper" style=" text-align:center;background-color: #fff;font-family: Arial, sans-serif; padding: 0px 0px 0px; " >
          <h2 style="color: #414141; font-size: 18px; font-weight: 400; line-height: 36px; margin: 0px;">
            {{ 'mail.label.lead_assessment' | translate}}
          </h2>
        </td>
      </tr>
      <tr>
        <td style="background-color: #fff;padding: 0px 0px 32px; text-align: center">
          <img style="display: block; height: auto; max-width: 50%; margin: 0 25%" src="https://al-cdn.s3-eu-central-1.amazonaws.com/wfz2024/email/az-lead-email-quality-{{starRating}}.png" width="300"  border="0" alt="">{{"\n"}}
        </td>
      </tr>

      <tr>
        <td class="dark-wrapper" style="background-color: #fff; font-family: Arial, sans-serif; padding: 0px 0px 8px; padding-left: 10.5%; padding-right: 10.5%;" >{{"\n"}}
          <h2 style="color: #414141; font-size: 24px; font-weight: 400; line-height: 32px; margin: 0px;">
            {{ 'mail.label.lead' | translate}}
          </h2>
        </td>
      </tr>
      {{"\n"}}
      <tr>
        <td class="dark-wrapper" style="background-color: #fff;font-size: 16px; line-height:24px; font-weight: 400; font-family: Arial, sans-serif; padding: 0px 0px 32px; padding-left: 10.5%; padding-right: 10.5%;">{{"\n"}}
          <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
            <tbody>
              <tr>
                <td style="width: 200px">{{ 'mail.label.source.where' | translate }}</td>
                <td><a [href]="source.url">{{source.woher}}</a></td>
              </tr>
              <tr>
                <td>{{ 'mail.label.source.what' | translate }}</td>
                <td>{{source.was}}</td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
      {{"\n"}}
      <tr>
        <td class="dark-wrapper" style="background-color: #fff; font-family: Arial, sans-serif; padding: 0px 0px 8px; padding-left: 10.5%; padding-right: 10.5%;" >
          <h2 style="color: #414141; font-size: 24px; font-weight: 400; line-height: 32px; margin: 0px;">
            {{'mail.label.personal_data'|translate}}
          </h2>
        </td>
      </tr>{{"\n"}}
      <tr>
        <td class="dark-wrapper" style="background-color: #fff;font-size: 16px; line-height:24px; font-weight: 400; font-family: Arial, sans-serif; padding: 0 0 32px; padding-left: 10.5%; padding-right: 10.5%;">{{"\n"}}
          <table role="presentation" cellspacing="0" cellpadding="0" border="0" style="width: 100%">
            <tbody>
              <tr>
                <td style="width: 200px">{{'mail.label.name'|translate}}</td>
                <td>{{name}}</td>
              </tr>
              <tr>
                <td>{{'mail.label.postal_code'|translate}}</td>
                <td>{{zipCode}}</td>
              </tr>
              {{"\n"}}
              <tr>
                <td>{{'mail.label.email'|translate}}</td>
                <td>{{emailAddress}}</td>
              </tr>
              <tr>
                <td>{{ 'mail.label.phone_number'|translate }}</td>
                <td>{{phoneNumber}}</td>
              </tr><tr>
                <td>{{'mail.label.availability'|translate}}</td>
                <td>{{availability}}</td>
              </tr>
              <tr>
                <td>{{'mail.label.is_customer'|translate}}</td>
                <td>{{isCustomer}}</td>
              </tr>
              <tr>
                <td>   </td>
                <td>   </td>
              </tr>
              {{"\n"}}
              <tr>
                <td>   </td>
                <td>   </td>
              </tr>


            </tbody>
          </table>
        </td>
      </tr>
      <!-- Start - Social Links -->
      <tr>
        <td class="dark-wrapper" style="background-color: #F5F5F5; padding: 24px 0 24px; text-align: center;">
          <table role="presentation" cellspacing="0" cellpadding="0" border="0">
            <tbody>
              <tr>
                <td class="dark-wrapper" style=" font-family: Arial, sans-serif; padding: 0px 0px 18px; " >
                  <h2 style="color: #414141; font-size: 20px; font-weight: 400; line-height: 36px; margin: 0px;">
                    {{'mail.label.feedback' | translate}}
                  </h2>
                </td>

              </tr>
              {{"\n"}}
            </tbody></table>

          <table role="presentation" cellspacing="0" cellpadding="0" border="0">
            <tbody><tr>
              <td style="padding: 0px 8px;">
                <a href="{{feedbackUrlNegative}}">
                  <img src="https://allianz-mailer.lespals.com/email/az-lead-email-feedback-negative.png" style="display: block; font-size: 0px; line-height: 0px;" width="40" height="40" border="0" alt="Negative">
                </a>
              </td>
              <td style="padding: 0px 8px;">
                <a href="{{feedbackUrlMedium}}">
                  <img src="https://allianz-mailer.lespals.com/email/az-lead-email-feedback-medium.png" style="display: block; font-size: 0px; line-height: 0px;" width="40" height="40" border="0" alt="Medium">
                </a>
              </td>
              <td style="padding: 0px 8px;">
                <a href="{{feedbackUrlPositive}}">
                  <img src="https://allianz-mailer.lespals.com/email/az-lead-email-feedback-positive.png" style="display: block; font-size: 0px; line-height: 0px;" width="40" height="40" border="0" alt="Positive">
                </a>
              </td>

            </tr>
            </tbody></table>
        </td>
      </tr>
      {{"\n"}}
      <!-- End - Social Links -->


      <!-- Start - Footer -->
      <tr>
        <td class="dark-wrapper" style="background-color: #fff; color: #414141; padding: 24px 32px;">
          <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
            <tbody><tr>
              <td class="dark-color-white" align="left" style="color: #414141; font-family: Arial, sans-serif; font-size: 14px; line-height: 20px; letter-spacing: 0.2px;text-align: center">
                {{'mail.label.copyright' | translate}}
                <a class="dark-link" style="color: #414141; padding-left: 16px; text-decoration: none;" href="https://www.allianz.ch/">www.allianz.ch</a>
              </td>
            </tr>
            </tbody></table>
        </td>
      </tr>
      <!-- End - Footer -->



    </tbody></table>
