import {Injectable} from '@angular/core';
import {Product, TrackingObject} from "./track-object";
import { HttpParams } from "@angular/common/http";
import {ActivatedRoute, Router} from "@angular/router";
import {AppDatastore} from "../app-store/app.datastore";
import {TranslateService} from "@ngx-translate/core";

export interface AllianzTrackingLibraryWindow extends Window {
  digitalDataLayer: any;
}

@Injectable({
  providedIn: 'root'
})

//TODO: Refactor this POS tracking implementation asap
export class TrackingService {
  public trackingObject: TrackingObject;
  public ls_gaID: string = '';
  public ls_type: string = 'GeneralAgentur';
  public ls_campaign: string = '';
  public ls_kunde: string = '';
  public ls_src: string = '';
  public ls_origin: string = '';
  public ls_werber: string = '';
  public ontrsustChecked = false;
  public appLanguage = '';

  constructor(private router: Router, private route: ActivatedRoute, private appDatastore: AppDatastore, private translationService: TranslateService) {
    // @ts-ignore
    this.ls_gaID = localStorage.getItem('_azch_elvia_data_mm_as') || '';
    this.ls_werber = localStorage.getItem('_azch_elvia_data_mm_we') || '';
    this.ls_campaign = localStorage.getItem('_azch_elvia_data_mm_campaign') || '';
    this.ls_kunde = localStorage.getItem('_azch_elvia_data_mm_nr') || '';
    this.ls_src = localStorage.getItem('_azch_elvia_data_mm_sc') || '';


    // @ts-ignore
    this.trackingObject = new TrackingObject();
    this.trackingObject.application.applicationId = "allianz-leadform";
    this.trackingObject.application.applicationName = "Allianz Leadform";
    this.trackingObject.application.virtualPageName = "start";
    this.trackingObject.application.startType = 'default';

    let tempString = new HttpParams({fromObject: this.route.snapshot.queryParams}).toString();
    // console.log('tempString', new URLSearchParams(window.location.search));
    this.trackingObject.page.pageInfo.URLqueryParams = (tempString.length > 1) ? '?' + tempString : '';
    this.trackingObject.page.pageInfo.fullURL = window.location.href;
    this.trackingObject.page.pageInfo.hostname = window.location.hostname;
    this.trackingObject.page.pageInfo.pageName = 'allianz.ch/content/dam/onemarketing/azch/allianz/leadform/' + this.trackingObject.application.virtualPageName
    // console.info('  this.trackingObject.page.pageInfo.URLqueryParams:', route.snapshot.fragment)

    this.route.queryParams.subscribe(params => {
      let params_ = new URLSearchParams(params);
      this.trackingObject.page.pageInfo.URLqueryParams = (params_.toString().length > 1) ? '?' + params_.toString() : '';
      let lang = params['lang'];
      this.trackingObject.page.pageInfo.language = lang;
      // @ts-ignore
      window.digitalDataLayer.push(this.trackingObject);
    });
  }

  trackConfiguration(type_: string) {
    // console.info('####trackConfiguration', type_);
    this.trackingObject.product.subCategory = type_;
    // @ts-ignore
    window.digitalDataLayer.push(this.trackingObject);
  }

  trackAgent() {
    if (!this.ontrsustChecked) {
      setTimeout(() => {
        // @ts-ignore
        _satellite.track("DCR: Consultation Start");
      }, 1000); // 1000 milliseconds = 1 second


      this.ontrsustChecked = true;
      // @ts-ignore
      let tempCampaign = (window.s) ? ((window.s.campaign) ? window.s.campaign : undefined) : undefined;
      if (tempCampaign != undefined && tempCampaign != '') {
        // Split the string by '|'
        const pairs = tempCampaign.split('|');

// Loop through the pairs and find the value of 'sc'
        pairs.forEach((pair: { split: (arg0: string) => [any, any]; }) => {
          const [key, value] = pair.split(':');
          if (key === 'as') {
            if (this.ls_gaID === '') this.ls_gaID = value;
          }
          if (key === 'we') {
            if (this.ls_werber === '') this.ls_werber = value;
          }
          if (key === 'sc') {
            if (this.ls_src === '') this.ls_src = value;
          }
          if (key === 'cp') {
            if (this.ls_campaign === '') this.ls_campaign = value;
          }
        });
      }
    }
    // if (this.ls_campaign === 'KB-Formular') {
    //   this.ls_origin = this.ls_campaign;
    // }
    if (localStorage.getItem('_azch_elvia_data_mm_as') === undefined) localStorage.setItem('_azch_elvia_data_mm_as', this.ls_gaID);
    if (localStorage.getItem('_azch_elvia_data_mm_sc') === undefined) localStorage.setItem('_azch_elvia_data_mm_sc', this.ls_src);
    // if (localStorage.getItem('_azch_elvia_data_mm_as') != undefined) localStorage.setItem('_azch_elvia_data_mm_as', this.ls_name);
    // @ts-ignore
    window.digitalDataLayer.push({
      leads: {
        origin: {
          name: this.ls_origin
        },
        intermediary: {
          name: this.ls_gaID,
          type: ((this.ls_gaID != '') ? 'GeneralAgentur' : ''),
          werber: this.ls_werber
        },
        campaign: {
          name: this.ls_campaign,
          source: this.ls_src
        },
        user: {
          customerNumber: this.ls_kunde,
        }
      },
      user: {
        residencePostalCode: this.appDatastore.userPlz


      }
    });
    var dataLayerEvent = {
      event: {
        eventInfo: {
          eventAction: "page load",
          eventName: "generic",
          eventType: "page"
        },
        category: {
          primaryCategory: "calculator"
        },
        attributes: {
          linkText: "",
          currentURL: window.location.href,
          targetURL: "",
          elementName: "",
          value: "",
          pos: "",
          componentPath: "",
          clickID: ""
        }
      }
    };
    // @ts-ignore
    // window.digitalDataLayer.push(dataLayerEvent);
    // console.log('LEAD TRACKED')!

  }

  trackStep(start: string, lang:string) {
    this.trackAgent();


    this.trackingObject.application.virtualPageName = '' + start;
    this.trackingObject.page.pageInfo.pageName = 'allianz.ch/content/dam/onemarketing/azch/allianz/leadform/' + this.trackingObject.application.virtualPageName
    this.trackingObject.page.pageInfo.language = lang;
    // this.route.params.subscribe(params=>{
    //   let lang = params['lang'];
    //   console.log('trackStep params lang:', lang);
    //   this.trackingObject.page.pageInfo.language = lang;
    // })


    // @ts-ignore
    window.digitalDataLayer.push(this.trackingObject);

// event comes always at last
    var dataLayerEvent = {
      event: {
        eventInfo: {
          eventAction: "page load",
          eventName: "generic",
          eventType: "page"
        },
        category: {
          primaryCategory: "calculator"
        },
        attributes: {
          linkText: "",
          currentURL: window.location.href,
          targetURL: "",
          elementName: "",
          value: "",
          pos: "",
          componentPath: "",
          clickID: ""
        }
      }
    };
    // @ts-ignore
    window.digitalDataLayer.push(dataLayerEvent);
  }

  trackLinkClick(_intern: boolean, _position: string, _href: string, innerText: string) {
    var dataLayerEvent = {
      event: {
        eventInfo: {
          eventAction: (_intern) ? "internal link" : "external link",
          eventName: (_position == 'header') ? "click on header" : "click on footer",
          eventType: "trigger"
        },
        category: {
          primaryCategory: ""
        },
        attributes: {
          linkText: innerText,
          currentURL: window.location.href,
          targetURL: _href,
          elementName: "text link",
          value: "",
          pos: "",
          componentPath: _position,
          clickID: ""
        }
      }
    };
    // @ts-ignore
    window.digitalDataLayer.push(dataLayerEvent);
  }
}
